import jwtdecode from "jwt-decode";

export function isAuthenticated(): boolean {
  let token = window.localStorage.getItem("id_token");
  const hasToken = !!token;
  if (token) {
    const id: any = jwtdecode(token);
    const expAt: number = id["exp"];
    const now = new Date().getTime() / 1000;
    return now < expAt;
  }
  return hasToken;
}

export function userGroups(): string[] {
  let token = window.localStorage.getItem("id_token");
  if (token) {
    const id: any = jwtdecode(token);
    return id["groups"];
  }
  return [];
}
export function email(): string {
  let token = window.localStorage.getItem("id_token");
  if (token) {
    const id: any = jwtdecode(token);
    return id["email"];
  }
  return "";
}
export function id(): string {
  let token = window.localStorage.getItem("id_token");
  if (token) {
    const id: any = jwtdecode(token);
    return id["sub"];
  }
  return "";
}
