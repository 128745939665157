import * as React from "react";
import withStyles, { WithStyles } from "material-ui/styles/withStyles";
import Typography from "material-ui/Typography";

const styles = {
  container: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60%",
    width: "100%"
  } as React.CSSProperties,
  root: {
    width: 300,
    height: 300,
    justifyContent: "center",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  } as React.CSSProperties
};

class NoAuth extends React.Component<WithStyles<keyof typeof styles>> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.root}>
          <Typography type="headline" gutterBottom>
            You don't have enough permissions to see this page
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NoAuth as any);
