import { AffiliationServiceService } from "./a8.pb";

class Auth {
  edit(headers: Headers) {
    const token = window.localStorage.getItem("id_token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
  }
}

const auth = new Auth();
let a8url = "https://apis.otsimo.com";
/*if (process.env.NODE_ENV === "development") {
  a8url = "https://apis.otsimo.xyz";
}*/
export const affiliationSvc = new AffiliationServiceService(a8url);
affiliationSvc.addHeaderEditor(auth);
