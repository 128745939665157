import * as React from "react";
import "../App.css";
import IconButton from "material-ui/IconButton";
import { PartnerInvitationClaim, Price } from "../api/a8.pb";
import List, {
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "material-ui/List";
import Divider from "material-ui/Divider";
import moment from "moment";
import Tooltip from "material-ui/Tooltip";
import Typography from "material-ui/Typography";

function Claim(props: { claim: PartnerInvitationClaim }) {
  const { claim } = props;
  let primary = "",
    statusText = "",
    secondStatusText = "",
    statusColor = "#ff2851";
  const secondary = (
    <Tooltip title={moment(claim.time).format("LL")}>
      <Typography color="secondary" type="body1">
        {moment.utc(claim.time).fromNow()}
      </Typography>
    </Tooltip>
  );

  switch (claim.type) {
    case "LIFETIME":
      primary = claim.userId + " purchased lifetime";
      statusText = claim.earning?.priceText ?? "";
      statusColor = "#00c853";
      break;
    case "CHURN":
      primary = claim.userId + " cancelled premium";
      statusText = claim.earning?.priceText ?? "";
      statusColor = "rgba(0, 0, 0, 0.54)";
      break;
    case "FREETRIAL":
      primary = claim.userId + " in free trial";
      statusText = "Pending";
      if (claim.earning && claim.earning.price! > 0) {
        secondStatusText = claim.earning?.priceText ?? "";
      }
      statusColor = "#ff9100";
      break;
    case "FREETRIAL_CANCEL":
      primary = claim.userId + " cancelled in free trial";
      statusText = "Cancel";
      statusColor = "#ff9100";
      if (claim.earning && claim.earning.price! > 0) {
        secondStatusText = claim.earning?.priceText ?? "";
      }
      break;
    case "PAID":
      primary = claim.userId + " subscribed";
      statusText = claim.earning?.priceText ?? "";
      statusColor = "#00c853";
      break;
    case "REFUND":
      primary = claim.userId + " has refunded order";
      statusText = "Refund";
      statusColor = "#d50000";
      if (claim.earning && claim.earning.price! > 0) {
        secondStatusText = claim.earning?.priceText ?? "";
      }
  }

  return (
    <ListItem button key={claim.time}>
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemSecondaryAction>
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span
            style={{
              fontSize: 16,
              marginRight: 16,
              fontFamily: "'Varela Round', sans-serif",
              color: statusColor
            }}
          >
            {statusText}
          </span>
          {secondStatusText && (
            <span
              style={{
                fontSize: 13,
                marginRight: 16,
                marginTop: 4,
                fontFamily: "'Varela Round', sans-serif",
                color: "rgba(0, 0, 0, 0.54)"
              }}
            >
              {secondStatusText}
            </span>
          )}
        </div>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

interface Props {
  claims: PartnerInvitationClaim[];
  income: Price;
}

export default class Earnings extends React.Component<Props> {
  render() {
    if (!this.props.claims || this.props.claims.length === 0) {
      return (
        <div
          style={{
            width: "100%",
            minHeight: 400,
            background: "#FAFAFA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h4 style={{ color: "#333333" }}>
            When a person register from your link, the status will be available
            here.
          </h4>
        </div>
      );
    }
    return (
      <div style={{ width: "100%", minHeight: 400 }}>
        <List
          style={{ minHeight: 400, background: "#FAFAFA", marginBottom: 16 }}
        >
          {this.props.claims.map(claim => (
            <Claim claim={claim} />
          ))}
        </List>
        <Divider />
        <ListItem button style={{ marginTop: 16 }}>
          <span
            style={{ fontSize: 20, fontFamily: "'Varela Round', sans-serif" }}
          >
            Estimated Earnings
          </span>
          <ListItemSecondaryAction>
            <IconButton aria-label="Comments">
              <span
                style={{
                  fontSize: 20,
                  fontFamily: "'Varela Round', sans-serif"
                }}
              >
                {this.props.income.priceText}
              </span>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </div>
    );
  }
}
