import * as React from "react";
import "../App.css";
import IconButton from "material-ui/IconButton";
import { IncomeWithdraw, Paid } from "../api/a8.pb";
import List, {
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from "material-ui/List";
import Divider from "material-ui/Divider";

interface Props {
  withdraws: IncomeWithdraw[];
  paidList: Paid[];
}

export default class Payments extends React.Component<Props> {
  state = {};

  render() {
    if (!this.props.withdraws || this.props.withdraws.length === 0) {
      return (
        <div
          style={{
            width: "100%",
            minHeight: 400,
            background: "#FAFAFA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h4 style={{ color: "#333333" }}>
            You don't have any pending payments.
          </h4>
        </div>
      );
    }
    return (
      <div style={{ width: "100%", minHeight: 400 }}>
        <List
          style={{ minHeight: 400, background: "#FAFAFA", marginBottom: 16 }}
        >
          {this.props.withdraws.map((claim: IncomeWithdraw) => {
            return this.renderWithdraw(claim);
          })}
        </List>
        <Divider style={{ marginBottom: 64 }} />
      </div>
    );
  }
  renderWithdraw(withdraw: IncomeWithdraw) {
    return (
      <ListItem button key={withdraw.withdrawId}>
        <ListItemText
          primary={withdraw.name + " " + withdraw.surname}
          secondary={new Date(
            (withdraw.createdAt || "").toString()
          ).toDateString()}
        />
        <ListItemSecondaryAction>
          <IconButton aria-label="Comments">
            <span
              style={{
                fontSize: 16,
                marginRight: 16,
                fontFamily: "'Varela Round', sans-serif"
              }}
            >
              {"In Process"}
            </span>
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}
