import * as React from "react";
import "../App.css";
import * as tools from "../helpers/tools";
import AppBar from "material-ui/AppBar";
import Toolbar from "material-ui/Toolbar";
import Typography from "material-ui/Typography";
import Menu, { MenuItem } from "material-ui/Menu";
import AccountCircle from "material-ui-icons/AccountCircle";
import ButtonBase from "material-ui/ButtonBase";
import { Partner } from "../api/a8.pb";
interface Props {
  user: Partner;
}

export default class OtsimoAppBar extends React.Component<Props> {
  state = {
    auth: true,
    anchorEl: undefined
  };

  handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  componentDidMount() {
    document.title = `Otsimo Affiliate: ${this.props.user.name}`;
  }

  handleRequestClose = () => {
    this.setState({ anchorEl: null });
  };
  handleLogout = () => {
    tools.logout();
  };
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography
            type="title"
            color="inherit"
            style={{ flex: 1, fontFamily: "'Varela Round', sans-serif" }}
          >
            Otsimo Affiliate: {this.props.user.name}
          </Typography>
          <div>
            <ButtonBase
              style={{ margin: 4, fontSize: 18 }}
              onClick={this.handleMenu}
            >
              <AccountCircle style={{ marginRight: 4 }} />
              {this.props.user.email}
            </ButtonBase>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onRequestClose={this.handleRequestClose}
            >
              <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}
