import * as React from "react";
import "../App.css";
import * as tools from "../helpers/tools";
import { withStyles } from "material-ui/styles";
import CurrentFinance from "../components/currentFinance";
import AffiliateLinkBox from "../components/affiliateLinkBox";
import OtsimoAppBar from "../components/otsimoAppBar";
import PremiumSales from "../components/premiumSales";
import Statistics from "../components/statistics";
import { connect } from "react-redux";
import { GlobalState, Dispatch } from "../store/user/types";
import * as actions from "../store/user/actions";
import { Partner, PaymentInfo } from "../api/a8.pb";

const styles = {
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "#00abcc05"
  },
  flex: {
    flex: 1
  },
  button: {
    margin: 4
  },
  leftIcon: {
    marginRight: 4
  }
};

interface Props {
  classes: any;
  user: Partner;
  payment: PaymentInfo;
  getUser: () => void;
}
class Dashboard extends React.Component<Props> {
  state = {};

  componentWillMount() {
    tools.checkLogin();
    if (this.props.user === undefined) {
      this.props.getUser();
    }
  }

  render() {
    const { classes, user } = this.props;
    if (!user) {
      return <h1>Loading</h1>;
    }
    return (
      <div className={classes.root}>
        <OtsimoAppBar user={user} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              maxWidth: 1368,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignSelf: "center",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                maxWidth: 1368,
                padding: 32,
                flexDirection: "row"
              }}
            >
              <div style={{ flex: 3 }}>
                <CurrentFinance />
              </div>
              <div style={{ flex: 2, marginLeft: 32 }}>
                <AffiliateLinkBox
                  link={"https://otsimo.com/" + user.attribution!.websiteSlug}
                />
              </div>
            </div>
            <Statistics />
            <PremiumSales />
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state: GlobalState, own: Props) {
  return {
    user: state.user.user,
    payment: state.user.payment
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUser: () => dispatch(actions.getUser())
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dashboard as any));
