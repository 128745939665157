import * as React from "react";
import "../App.css";
import Typography from "material-ui/Typography";
import Card, { CardContent } from "material-ui/Card";
import { PartnerStatistics } from "../api/a8.pb";
import { connect } from "react-redux";
import { GlobalState, Dispatch } from "../store/user/types";
import Tooltip from "material-ui/Tooltip";
import CircularProgress from "material-ui/Progress/CircularProgress";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui-icons/Refresh";
import * as actions from "../store/user/actions";

function StatBox(props: { title: string; number: number }) {
  return (
    <div style={{ flex: 1 }}>
      <Typography
        style={{
          fontSize: 20,
          fontFamily: "'Varela Round', sans-serif",
          color: "#999999"
        }}
        type="headline"
        component="h2"
      >
        {props.title}
      </Typography>
      <Typography
        style={{
          fontSize: 32,
          marginTop: 8,
          fontFamily: "'Varela Round', sans-serif"
        }}
        type="headline"
        component="h2"
      >
        {props.number}
      </Typography>
    </div>
  );
}

function Statistics(props: {
  stats?: PartnerStatistics;
  statsFailed?: boolean;
  reloadStats: () => void;
}) {
  return (
    <div style={{ width: "100%" }}>
      <Card style={{ margin: "0 32px 0 32px", padding: 8 }}>
        <CardContent>
          <h1 style={{ fontSize: 20, flex: 1 }}>Statistics</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "stretch",
              marginTop: 24
            }}
          >
            {props.stats && (
              <>
                <StatBox title="Click" number={props.stats.click!} />
                <StatBox title="Install" number={props.stats.install!} />
                <StatBox
                  title="Registered Users"
                  number={props.stats.register!}
                />
                <StatBox
                  title="Free Trial Started"
                  number={props.stats.paidOrTrial!}
                />
              </>
            )}
            {!props.stats && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1
                }}
              >
                {props.statsFailed === true ? (
                  <Tooltip title="Reload Statistics">
                    <IconButton onClick={props.reloadStats}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function mapStateToProps(state: GlobalState) {
  return {
    stats: state.user.stats,
    statsFailed: state.user.statsFailed
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    reloadStats: () => dispatch(actions.reloadStats())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
