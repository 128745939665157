// Code generated by protoc-gen-js-fetch.
// DO NOT EDIT!

import * as google_protobuf_google_protobuf_timestamp from "./google/protobuf/timestamp.pb";
import * as google_protobuf_google_protobuf_field_mask from "./google/protobuf/field_mask.pb";

export type PaymentPolicyType = "PERITEM" | "PERCENT";
export const PaymentPolicyType_PERITEM: PaymentPolicyType = "PERITEM";
export const PaymentPolicyType_PERCENT: PaymentPolicyType = "PERCENT";

export const ALL_PaymentPolicyType_VALUES: PaymentPolicyType[] = [
  PaymentPolicyType_PERITEM,
  PaymentPolicyType_PERCENT
];

export type PayOn = "FREE_TRIAL" | "PAYMENT" | "INSTALL" | "REGISTER";
export const PayOn_FREE_TRIAL: PayOn = "FREE_TRIAL";
export const PayOn_PAYMENT: PayOn = "PAYMENT";
export const PayOn_INSTALL: PayOn = "INSTALL";
export const PayOn_REGISTER: PayOn = "REGISTER";

export const ALL_PayOn_VALUES: PayOn[] = [
  PayOn_FREE_TRIAL,
  PayOn_PAYMENT,
  PayOn_INSTALL,
  PayOn_REGISTER
];

export type PremiumType =
  | "FREETRIAL"
  | "PAID"
  | "FREETRIAL_CANCEL"
  | "CHURN"
  | "REFUND"
  | "LIFETIME";
export const PremiumType_FREETRIAL: PremiumType = "FREETRIAL";
export const PremiumType_PAID: PremiumType = "PAID";
export const PremiumType_FREETRIAL_CANCEL: PremiumType = "FREETRIAL_CANCEL";
export const PremiumType_CHURN: PremiumType = "CHURN";
export const PremiumType_REFUND: PremiumType = "REFUND";
export const PremiumType_LIFETIME: PremiumType = "LIFETIME";

export const ALL_PremiumType_VALUES: PremiumType[] = [
  PremiumType_FREETRIAL,
  PremiumType_PAID,
  PremiumType_FREETRIAL_CANCEL,
  PremiumType_CHURN,
  PremiumType_REFUND,
  PremiumType_LIFETIME
];

/**
Partner creates invitations, which can be claimed by the users
Has monthly, weekly incomes; of which he can request a withdrawal
*/
export interface Partner {
  partnerId?: string;
  email?: string;
  name?: string;
  createdAt?: google_protobuf_google_protobuf_timestamp.Timestamp;
  attribution?: PartnerAttribution;
  policy?: PaymentPolicy;
}

export const Partner_partnerId = "partner_id";
export const Partner_email = "email";
export const Partner_name = "name";
export const Partner_createdAt = "created_at";
export const Partner_attribution = "attribution";
export const Partner_policy = "policy";
export interface PaymentInfo {
  withdraws?: IncomeWithdraw[];
  paidList?: Paid[];
  claims?: PartnerInvitationClaim[];
  income?: Income;
}

export const PaymentInfo_withdraws = "withdraws";
export const PaymentInfo_paidList = "paid_list";
export const PaymentInfo_claims = "claims";
export const PaymentInfo_income = "income";
export interface BranchInfo {
  /**
Deep link is the unique link for the partner
*/
  deepLink?: string;
  /**
Code can be used inside the app
*/
  code?: string;
  claims?: PartnerInvitationClaim[];
  income?: Income;
  withdraws?: IncomeWithdraw[];
  /**
All the price
*/
  paidList?: Paid[];
  /**
IDs of users who have claimed so far, needed for cross-checking in
getPartner
*/
  usersPaid?: string[];
}

export const BranchInfo_deepLink = "deep_link";
export const BranchInfo_code = "code";
export const BranchInfo_claims = "claims";
export const BranchInfo_income = "income";
export const BranchInfo_withdraws = "withdraws";
export const BranchInfo_paidList = "paid_list";
export const BranchInfo_usersPaid = "users_paid";
export interface PartnerAttribution {
  websiteSlug?: string;
  trackerToken?: string;
  clickUrl?: string;
  impressionUrl?: string;
  campaignName?: string;
  adgroupName?: string;
}

export const PartnerAttribution_websiteSlug = "website_slug";
export const PartnerAttribution_trackerToken = "tracker_token";
export const PartnerAttribution_clickUrl = "click_url";
export const PartnerAttribution_impressionUrl = "impression_url";
export const PartnerAttribution_campaignName = "campaign_name";
export const PartnerAttribution_adgroupName = "adgroup_name";
export interface PartnerStatistics {
  click?: number;
  install?: number;
  register?: number;
  paidOrTrial?: number;
}

export const PartnerStatistics_click = "click";
export const PartnerStatistics_install = "install";
export const PartnerStatistics_register = "register";
export const PartnerStatistics_paidOrTrial = "paid_or_trial";
export interface PaymentPolicy {
  type?: PaymentPolicyType;
  payon?: PayOn;
  /**
Percent is from 0 to 1
*/
  percent?: number;
  /**
Per Item Pricing
*/
  peritem?: Price;
}

export const PaymentPolicy_type = "type";
export const PaymentPolicy_payon = "payon";
export const PaymentPolicy_percent = "percent";
export const PaymentPolicy_peritem = "peritem";
/**
Previously paid amount
*/
export interface Paid {
  price?: Price;
  date?: google_protobuf_google_protobuf_timestamp.Timestamp;
}

export const Paid_price = "price";
export const Paid_date = "date";
export interface PartnerInvitationClaim {
  userId?: string;
  code?: number;
  isPremium?: boolean;
  service?: string;
  type?: PremiumType;
  time?: google_protobuf_google_protobuf_timestamp.Timestamp;
  earning?: Price;
}

export const PartnerInvitationClaim_userId = "user_id";
export const PartnerInvitationClaim_code = "code";
export const PartnerInvitationClaim_isPremium = "is_premium";
export const PartnerInvitationClaim_service = "service";
export const PartnerInvitationClaim_type = "type";
export const PartnerInvitationClaim_time = "time";
export const PartnerInvitationClaim_earning = "earning";
export interface Price {
  /**
Formatted price of the item, including its currency sign.
The price does not include tax.
*/
  priceText?: string;
  /**
Price in cent-units, where cent-units equal one unit of the
currency. For example, if price is "€7.99", price_amount_micros is "799".
This value represents the localized, rounded price for a particular currency.
*/
  price?: number;
  /**
ISO 4217 currency code for price. For example, if price is specified in
British pounds sterling, price_currency_code is "GBP".
*/
  currencyCode?: string;
}

export const Price_priceText = "price_text";
export const Price_price = "price";
export const Price_currencyCode = "currency_code";
export interface Income {
  total?: Price;
  month?: Price;
  week?: Price;
}

export const Income_total = "total";
export const Income_month = "month";
export const Income_week = "week";
/**
The necessary info in order for the transmission to happen
*/
export interface IncomeWithdrawReq {
  name?: string;
  surname?: string;
  zipCode?: string;
  address?: string;
  mobilePhone?: string;
  email?: string;
  partnerId?: string;
}

export const IncomeWithdrawReq_name = "name";
export const IncomeWithdrawReq_surname = "surname";
export const IncomeWithdrawReq_zipCode = "zip_code";
export const IncomeWithdrawReq_address = "address";
export const IncomeWithdrawReq_mobilePhone = "mobile_phone";
export const IncomeWithdrawReq_email = "email";
export const IncomeWithdrawReq_partnerId = "partner_id";
/**
The withdraw info stored in DB, w/ its unique id and creation date
*/
export interface IncomeWithdraw {
  withdrawId?: string;
  name?: string;
  surname?: string;
  zipCode?: string;
  address?: string;
  mobilePhone?: string;
  email?: string;
  createdAt?: google_protobuf_google_protobuf_timestamp.Timestamp;
  answered?: boolean;
}

export const IncomeWithdraw_withdrawId = "withdraw_id";
export const IncomeWithdraw_name = "name";
export const IncomeWithdraw_surname = "surname";
export const IncomeWithdraw_zipCode = "zip_code";
export const IncomeWithdraw_address = "address";
export const IncomeWithdraw_mobilePhone = "mobile_phone";
export const IncomeWithdraw_email = "email";
export const IncomeWithdraw_createdAt = "created_at";
export const IncomeWithdraw_answered = "answered";
/**
Status of the withdraw operation, if it actually reached to us or not
*/
export interface WithdrawResponse {
  status?: number;
  req?: IncomeWithdraw;
}

export const WithdrawResponse_status = "status";
export const WithdrawResponse_req = "req";
export interface CreatePartnerReq {
  id?: string;
  email?: string;
  name?: string;
  attribution?: PartnerAttribution;
  policy?: PaymentPolicy;
}

export const CreatePartnerReq_id = "id";
export const CreatePartnerReq_email = "email";
export const CreatePartnerReq_name = "name";
export const CreatePartnerReq_attribution = "attribution";
export const CreatePartnerReq_policy = "policy";
/**
The info of the partner user and the paid amount w/ date
*/
export interface PayRequest {
  id?: string;
  email?: string;
  price?: Price;
  /**
The actual date of the operation
*/
  date?: google_protobuf_google_protobuf_timestamp.Timestamp;
}

export const PayRequest_id = "id";
export const PayRequest_email = "email";
export const PayRequest_price = "price";
export const PayRequest_date = "date";
export interface ListPartnersReq {}

export interface ListPartnerItem {
  id?: string;
  email?: string;
  name?: string;
}

export const ListPartnerItem_id = "id";
export const ListPartnerItem_email = "email";
export const ListPartnerItem_name = "name";
export interface ListPartnersRes {
  partners?: ListPartnerItem[];
}

export const ListPartnersRes_partners = "partners";
export interface GetPartnerReq {
  id?: string;
}

export const GetPartnerReq_id = "id";
export interface UpdatePartnerReq {
  fields?: google_protobuf_google_protobuf_field_mask.FieldMask;
  partner?: Partner;
}

export const UpdatePartnerReq_fields = "fields";
export const UpdatePartnerReq_partner = "partner";
export interface PartnerLinks {
  slug?: string;
  name?: string;
  link?: string;
  impressionLink?: string;
}

export const PartnerLinks_slug = "slug";
export const PartnerLinks_name = "name";
export const PartnerLinks_link = "link";
export const PartnerLinks_impressionLink = "impression_link";
export interface ListPartnerLinksRes {
  partners?: PartnerLinks[];
}

export const ListPartnerLinksRes_partners = "partners";
export class AffiliationServiceService {
  private host: string;
  private headerEditors: any[] = [];
  /**
   * @param {string} url the service host url
   */
  constructor(url: string) {
    this.host = url;
    this.headerEditors = [];
  }

  addHeaderEditor(m: any) {
    this.headerEditors.push(m);
  }
  /**
CreatePartner registers a new partner if the auth group is admin
*/

  async createPartner(createPartnerReq: CreatePartnerReq): Promise<Partner> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/createPartner`);
    const _init = {
      method: "PUT",
      headers: _headers,
      body: JSON.stringify(createPartnerReq)
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updatePartner(updatePartnerReq: UpdatePartnerReq): Promise<Partner> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/updatePartner`);
    const _init = {
      method: "POST",
      headers: _headers,
      body: JSON.stringify(updatePartnerReq)
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPartner(id: string): Promise<Partner> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/getPartner/${id}`);
    const _init = {
      method: "GET",
      headers: _headers,
      body: undefined
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPartnerPaymentInfo(id: string): Promise<PaymentInfo> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/paymentInfo/${id}`);
    const _init = {
      method: "GET",
      headers: _headers,
      body: undefined
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPartnerStatistics(id: string): Promise<PartnerStatistics> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/partnerStatistics/${id}`);
    const _init = {
      method: "GET",
      headers: _headers,
      body: undefined
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async listPartners(): Promise<ListPartnersRes> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/list`);
    const _init = {
      method: "GET",
      headers: _headers,
      body: undefined
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async listPartnersForWebsite(): Promise<ListPartnerLinksRes> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/listLinks`);
    const _init = {
      method: "GET",
      headers: _headers,
      body: undefined
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
RequestIncome is called when the partner wants to get their money
*/

  async requestIncome(
    incomeWithdrawReq: IncomeWithdrawReq
  ): Promise<WithdrawResponse> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/requestIncome`);
    const _init = {
      method: "POST",
      headers: _headers,
      body: JSON.stringify(incomeWithdrawReq)
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
PayMoney is manually called every time some amount of money has been paid
*/

  async payMoney(payRequest: PayRequest): Promise<Partner> {
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    for (let i = 0; i < this.headerEditors.length; ++i) {
      this.headerEditors[i].edit(_headers);
    }
    const _url = new URL(`${this.host}/api/v1/a8/pay`);
    const _init = {
      method: "POST",
      headers: _headers,
      body: JSON.stringify(payRequest)
    } as RequestInit;

    const _req = new Request(_url.toString(), _init);
    try {
      const resp = await fetch(_req);
      if (resp.status !== 200) {
        const rj = await resp.json();
        return Promise.reject(rj);
      }
      return resp.json();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
