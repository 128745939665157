import * as React from "react";
import "../App.css";
import Typography from "material-ui/Typography";
import CircularProgress from "material-ui/Progress/CircularProgress";
import Card from "material-ui/Card";
import { PaymentInfo } from "../api/a8.pb";
import Tabs, { Tab } from "material-ui/Tabs";
import Earnings from "./earnings";
import Payments from "./payments";
import WithdrawButton from "./withdrawButton";
import { GlobalState, Dispatch } from "../store/user/types";
import { connect } from "react-redux";
import * as actions from "../store/user/actions";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui-icons/Refresh";
import Tooltip from "material-ui/Tooltip";

interface Props {
  payment?: PaymentInfo;
  paymentFailed?: boolean;
  reloadPayment: () => void;
}

class PremiumSales extends React.Component<Props> {
  state = {
    value: 0
  };
  handleTabChange = (event: any, value: number) => {
    this.setState({ value });
  };

  renderTabContent() {
    if (!this.props.payment) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: 100,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {this.props.paymentFailed === true ? (
            <Tooltip title="Reload Premium Sale Information">
              <IconButton onClick={this.props.reloadPayment}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress />
          )}
        </div>
      );
    }
    if (this.state.value === 0) {
      return (
        <Earnings
          key="0"
          claims={this.props.payment.claims!}
          income={this.props.payment.income?.total!}
        />
      );
    } else {
      return (
        <Payments
          key="1"
          withdraws={this.props.payment.withdraws!}
          paidList={this.props.payment.paidList!}
        />
      );
    }
  }

  render() {
    const { value } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Card style={{ margin: 32, padding: 16 }}>
          <h1 style={{ flex: 1 }}>Premium Sales</h1>
          <Tabs
            indicatorColor="#00abcc"
            value={value}
            onChange={this.handleTabChange}
          >
            <Tab key="0" label="Earnings" />
            <Tab key="1" label="Your Payments" />
          </Tabs>
          {this.renderTabContent()}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-end",
              justifyContent: "flex-end",
              width: "100%"
            }}
          >
            <WithdrawButton
              disabled={
                !this.props.payment ||
                !this.props.payment.income ||
                !this.props.payment.income.total ||
                this.props.payment.income.total.price! < 5000
              }
              style={{ marginLeft: "auto" }}
            />
            <Typography style={{ marginLeft: "auto" }} type="caption">
              *for money withdraw, at least $50 must be in your account
            </Typography>
          </div>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state: GlobalState) {
  return {
    payment: state.user.payment,
    paymentFailed: state.user.paymentFailed
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    reloadPayment: () => dispatch(actions.reloadPayment())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PremiumSales);
