import * as React from "react";
import Button from "material-ui/Button";
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "material-ui/Dialog";
import TextField from "material-ui/TextField";
import { withStyles } from "material-ui/styles";
import purple from "material-ui/colors/purple";
import { Theme } from "material-ui/styles/createMuiTheme";
import { StyleRules } from "material-ui/styles/withStyles";
import MaskedInput from "react-text-mask";
import { IncomeWithdrawReq } from "../api/a8.pb";
import { affiliationSvc } from "../api";
import Snackbar from "material-ui/Snackbar";

const styles = (theme: Theme) =>
  ({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    formControl: {
      margin: theme.spacing.unit
    },
    inputLabelFocused: {
      color: purple[500]
    },
    inputInkbar: {
      "&:after": {
        backgroundColor: purple[500]
      }
    },
    textFieldRoot: {
      padding: 0,
      "label + &": {
        marginTop: theme.spacing.unit * 3
      }
    },
    textFieldInput: {
      borderRadius: 4,
      background: theme.palette.common.white,
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 12px",
      width: "calc(100% - 24px)",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    },
    textFieldFormLabel: {
      fontSize: 18
    }
  } as StyleRules);

interface Props {
  fullScreen: boolean;
  disabled: boolean;
  style: any;
  classes: any;
  theme?: Theme;
}
interface State {
  withdrawRequest: IncomeWithdrawReq;
  open: boolean;
  phone: string;
  snackOpen: boolean;
  snackMessage: string;
}
class TextMaskCustom extends React.Component {
  render() {
    return (
      <MaskedInput
        {...this.props}
        mask={[
          "+",
          /[1-9]/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        placeholderChar={"\u2000"}
        showMask
      />
    );
  }
}

class ResponsiveDialog extends React.Component<Props, State> {
  state = {
    open: false,
    phone: "+1",
    snackOpen: false,
    snackMessage: "Error",
    withdrawRequest: {} as IncomeWithdrawReq
  } as State;

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
    console.log(this.state.withdrawRequest);
  };

  submit = () => {
    this.setState({ open: false });
    let req = this.state.withdrawRequest;
    affiliationSvc
      .requestIncome(req)
      .then(res => {
        this.setState({
          snackMessage: "Your request has been recorded",
          snackOpen: true
        });
      })
      .catch(err => {
        this.setState({ snackMessage: err.error, snackOpen: true });
      });
    console.log(this.state.withdrawRequest);
  };
  render() {
    const { classes } = this.props;

    return (
      <div style={this.props.style}>
        <Button
          disabled={this.props.disabled}
          onClick={this.handleClickOpen}
          style={{
            alignSelf: "flex-start",
            margin: 4,
            marginLeft: "auto",
            color: this.props.disabled ? "#CCCCCC" : "#00abcc",
            border: this.props.disabled
              ? "1px solid #CCCCCC"
              : "1px solid #00abcc"
          }}
        >
          Money Withdraw
        </Button>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose}>
          <DialogTitle>{"Withdraw Request"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill the from to withdraw.
            </DialogContentText>
            <TextField
              style={{ marginTop: 16 }}
              label="Name"
              onChange={e => {
                this.setState({
                  withdrawRequest: {
                    ...this.state.withdrawRequest,
                    name: (e as any).target.value
                  }
                });
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput
                }
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldFormLabel
              }}
              fullWidth
            />
            <TextField
              style={{ marginTop: 16 }}
              label="Surname"
              onChange={e => {
                this.setState({
                  withdrawRequest: {
                    ...this.state.withdrawRequest,
                    surname: (e as any).target.value
                  }
                });
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput
                }
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldFormLabel
              }}
              fullWidth
            />
            <TextField
              style={{ marginTop: 16 }}
              label="Zip Code"
              onChange={e => {
                this.setState({
                  withdrawRequest: {
                    ...this.state.withdrawRequest,
                    zipCode: (e as any).target.value
                  }
                });
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput
                }
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldFormLabel
              }}
              fullWidth
            />
            <TextField
              style={{ marginTop: 16 }}
              label="Address"
              onChange={e => {
                this.setState({
                  withdrawRequest: {
                    ...this.state.withdrawRequest,
                    address: (e as any).target.value
                  }
                });
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput
                }
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldFormLabel
              }}
              fullWidth
            />
            <TextField
              style={{ marginTop: 16 }}
              label="Phone"
              value={this.state.withdrawRequest.mobilePhone}
              onChange={e => {
                this.setState({
                  withdrawRequest: {
                    ...this.state.withdrawRequest,
                    mobilePhone: (e as any).target.value
                  }
                });
              }}
              InputProps={{
                inputComponent: TextMaskCustom,
                disableUnderline: true,
                classes: {
                  root: classes.textFieldRoot,
                  input: classes.textFieldInput
                }
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldFormLabel
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.submit} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.snackOpen}
          onRequestClose={() => {
            this.setState({ snackOpen: false });
          }}
          SnackbarContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ResponsiveDialog) as any;
