import * as React from "react";
import "../App.css";
import Typography from "material-ui/Typography";
import Card, { CardContent } from "material-ui/Card";
import { Price, PaymentInfo } from "../api/a8.pb";
import { connect } from "react-redux";
import { GlobalState, Dispatch } from "../store/user/types";
import Tooltip from "material-ui/Tooltip";
import CircularProgress from "material-ui/Progress/CircularProgress";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui-icons/Refresh";
import * as actions from "../store/user/actions";

function priceToText(price: Price): string {
  if (
    price.priceText?.startsWith("$") ||
    price.priceText?.startsWith("£") ||
    price.priceText?.startsWith("€")
  ) {
    return price!.priceText.substring(1) + " " + price.currencyCode;
  }
  return price!.priceText + " " + price.currencyCode;
}

function EarningBox(props: { title: string; price: Price }) {
  return (
    <div style={{ flex: 1 }}>
      <Typography
        style={{
          fontSize: 20,
          fontFamily: "'Varela Round', sans-serif",
          color: "#999999"
        }}
        type="headline"
        component="h2"
      >
        {props.title}
      </Typography>
      <Typography
        style={{
          fontSize: 32,
          marginTop: 8,
          fontFamily: "'Varela Round', sans-serif"
        }}
        type="headline"
        component="h2"
      >
        {priceToText(props.price!)}
      </Typography>
    </div>
  );
}

function CurrentFinance(props: {
  payment?: PaymentInfo;
  paymentFailed?: boolean;
  reloadPayment: () => void;
}) {
  return (
    <div style={{ height: "100%" }}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <h1 style={{ fontSize: 20, flex: 1 }}>Earning Overview</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "stretch",
              marginTop: 24,
              marginBottom: 24
            }}
          >
            {props.payment && (
              <>
                <EarningBox
                  title="This Week"
                  price={props.payment!.income!.week!}
                />
                <EarningBox
                  title="This Month"
                  price={props.payment!.income!.month!}
                />
                <EarningBox
                  title="Total"
                  price={props.payment!.income!.total!}
                />
              </>
            )}
            {!props.payment && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1
                }}
              >
                {props.paymentFailed === true ? (
                  <Tooltip title="Reload Earning Information">
                    <IconButton onClick={props.reloadPayment}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <CircularProgress />
                )}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

function mapStateToProps(state: GlobalState) {
  return {
    payment: state.user.payment,
    paymentFailed: state.user.paymentFailed
  };
}
function mapDispatchToProps(dispatch: Dispatch) {
  return {
    reloadPayment: () => dispatch(actions.reloadPayment())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrentFinance);
