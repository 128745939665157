import * as React from "react";
import { Nav } from "./navigator";

class App extends React.Component {
  render() {
    return <Nav />;
  }
}

export default App;
