import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import createHistory from "history/createBrowserHistory";
import { routerReducer as router, routerMiddleware } from "react-router-redux";
import { GlobalState } from "./user/types";
import user from "./user/reducer";
// Create a history of your choosing (we're using a browser history in this case)
export const PATH_BASENAME = "/";
export const history = createHistory({ basename: PATH_BASENAME });

// Build the middleware for intercepting and dispatching navigation actions
const historyMiddleware = routerMiddleware(history);

const appReducer = combineReducers<GlobalState>({ router, user });
const store = createStore<GlobalState>(
  appReducer,
  applyMiddleware(thunkMiddleware, historyMiddleware)
);
export default store;
