import { Dispatch } from "./types";
import { push } from "react-router-redux";
import { PartnerInvitationClaim, IncomeWithdrawReq } from "../../api/a8.pb";
import { affiliationSvc } from "../../api";
import * as auth from "../../auth";

export function redirectToLogin() {
  return (dispatch: Dispatch) => {
    dispatch(push("/login"));
  };
}

export function login() {
  return (dispatch: Dispatch) => {
    dispatch({ type: "LoginSuccess" });
    //dispatch(getUser())
    dispatch(push("/"));
  };
}

export function withdrawMoney(rew: IncomeWithdrawReq) {
  return (dispatch: Dispatch) => {
    affiliationSvc.requestIncome(rew).then(partner => {
      console.log(partner);
      //dispatch(updateUser(partner));
    });
  };
}
export function reloadStats() {
  return (dispatch: Dispatch) => {
    dispatch({ type: "StatsReset" });
    affiliationSvc
      .getPartnerStatistics(auth.id())
      .then(res => {
        dispatch({ type: "SetStats", statistics: res });
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: "StatsFailed" });
      });
  };
}
export function reloadPayment() {
  return (dispatch: Dispatch) => {
    dispatch({ type: "PaymentReset" });
    affiliationSvc
      .getPartnerPaymentInfo(auth.id())
      .then(res => {
        dispatch({ type: "SetPayment", payment: res });
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: "PaymentFailed" });
      });
  };
}
export function getUser() {
  return (dispatch: Dispatch) => {
    affiliationSvc
      .getPartner(auth.id())
      .then(partner => {
        console.log(partner);
        dispatch({
          type: "SetUser",
          partner: partner
        });
        affiliationSvc
          .getPartnerPaymentInfo(auth.id())
          .then(res => {
            dispatch({ type: "SetPayment", payment: res });
          })
          .catch(err => {
            console.error(err);
            dispatch({ type: "PaymentFailed" });
          });
        affiliationSvc
          .getPartnerStatistics(auth.id())
          .then(res => {
            dispatch({ type: "SetStats", statistics: res });
          })
          .catch(err => {
            console.error(err);
            dispatch({ type: "StatsFailed" });
          });
      })
      .catch(err => {
        dispatch({
          type: "SetUser",
          partner: {
            partnerId: "123",
            name: "Demo",
            email: "partner@otsimo.com",
            attribution: {
              adgroupName: "",
              campaignName: "",
              clickUrl: "",
              impressionUrl: "",
              trackerToken: "",
              websiteSlug: "partner"
            },
            policy: {
              payon: "FREE_TRIAL",
              peritem: { currencyCode: "USD", price: 1000, priceText: "$10.00" }
            }
          }
        });
        dispatch({
          type: "SetPayment",
          payment: {
            paidList: [
              {
                date: new Date().toString(),
                price: {
                  priceText: "$20.00",
                  price: 2000,
                  currencyCode: "USD"
                }
              }
            ],
            income: {
              week: {
                priceText: "$10.00",
                price: 1000,
                currencyCode: "USD"
              },
              month: {
                priceText: "$20.00",
                price: 2000,
                currencyCode: "USD"
              },
              total: {
                priceText: "$40.00",
                price: 4000,
                currencyCode: "USD"
              }
            },
            claims: [
              {
                claimedBy: "example@gmail.com",
                claimedAt: "0",
                os: "ios",
                appVersion: "4.0.0",
                premiumStatus: "PREMIUM",
                isPremium: false
              } as PartnerInvitationClaim,
              {
                claimedBy: "example@gmail.com",
                claimedAt: "1",
                os: "ios",
                appVersion: "4.0.0",
                premiumStatus: "NONE",
                isPremium: true
              } as PartnerInvitationClaim,
              {
                claimedBy: "example@gmail.com",
                claimedAt: "2",
                os: "ios",
                appVersion: "4.0.0",
                premiumStatus: "TRIAL",
                isPremium: true
              } as PartnerInvitationClaim,
              {
                claimedBy: "example@gmail.com",
                claimedAt: "3",
                os: "ios",
                appVersion: "4.0.0",
                premiumStatus: "PREMIUM",
                isPremium: true
              } as PartnerInvitationClaim,
              {
                claimedBy: "example@gmail.com",
                claimedAt: "4",
                os: "ios",
                appVersion: "4.0.0",
                premiumStatus: "CANCELED",
                isPremium: true
              } as PartnerInvitationClaim
            ]
          }
        });
      });
  };
}
