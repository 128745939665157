import * as React from "react";
import Button from "material-ui/Button";
import withStyles, { WithStyles } from "material-ui/styles/withStyles";
import Typography from "material-ui/Typography";
import { CircularProgress } from "material-ui/Progress";

const styles = {
  container: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60%",
    width: "100%"
  } as React.CSSProperties,
  button: {},
  root: {
    width: 300,
    height: 300,
    justifyContent: "center",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  } as React.CSSProperties
};
let clientId = "m4y22orjuakqkvq7rv89@partners.otsimo.com";
let authEnd = "https://connect.otsimo.com/auth";
let tokenApi = "https://apis.otsimo.com/token";
if (process.env.NODE_ENV === "development") {
  clientId = "fpllngzieyoh43e0133o@localhost";
  authEnd = "https://connect.otsimo.xyz/auth";
  tokenApi = "https://apis.otsimo.xyz/token";
}

class Login extends React.Component<WithStyles<keyof typeof styles>> {
  state = {
    inCodeExchange: false
  };

  get redirectUrl() {
    return `${window.location.origin}/login`;
  }

  get authEndpoint() {
    return authEnd;
  }

  componentDidMount() {
    let decoded = decodeURIComponent(window.location.search);
    if (!decoded.length) {
      return;
    }
    const search = decoded.split("&");
    let state = "";
    let code = "";
    if (search.length === 2) {
      if (search[0].startsWith("?code=")) {
        code = search[0].substring(6);
      }
      if (search[1].startsWith("code=")) {
        code = search[1].substring(5);
      }
      if (search[0].startsWith("?state=")) {
        state = search[0].substring(7);
      }
      if (search[1].startsWith("state=")) {
        state = search[1].substring(6);
      }
      this.requestToken(code, state).catch(err => {
        console.error("failed to login", err);
      });
    }
  }

  async requestToken(code: string, state: string) {
    this.setState({ inCodeExchange: true });
    const _headers = new Headers();
    _headers.append("Content-Type", "application/json");
    let data = {
      code: code,
      client_id: clientId,
      redirect_url: this.redirectUrl,
      scope: "openid",
      state: state,
      grant_type: "authorization_code"
    };
    const req = new Request(tokenApi, {
      method: "POST",
      headers: _headers,
      body: JSON.stringify(data)
    });
    const res = await fetch(req);
    const tok = await res.json();
    if (tok.access_token && tok.access_token.length > 0) {
      localStorage.setItem("id_token", tok.access_token);
      window.location.href = "/";
    }
  }

  startLogin = () => {
    let m: { [key: string]: string } = {
      response_type: "code",
      client_id: clientId,
      access_type: "offline",
      redirect_uri: this.redirectUrl,
      scope: "openid",
      state: "adminlogin"
    };
    const search = Object.keys(m)
      .map(k => `${k}=${encodeURIComponent(m[k])}`)
      .join("&");
    const url = `${this.authEndpoint}?${search}`;
    window.location.assign(url);
  };

  renderLogin() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography type="headline" gutterBottom>
          You have to Login
        </Typography>
        <Button
          raised
          color="accent"
          className={classes.button}
          onClick={this.startLogin}
        >
          Login
        </Button>
      </div>
    );
  }

  renderCode() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography type="headline" gutterBottom>
          Completing the Login
        </Typography>
        <CircularProgress />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        {this.state.inCodeExchange ? this.renderCode() : this.renderLogin()}
      </div>
    );
  }
}

export default withStyles(styles)(Login as any);
