import { push } from 'react-router-redux';
import store  from '../store';

export function checkLogin() {
    if (!store.getState().user.loggedIn) {
        store.dispatch(push("/login"))
    }
}
export function logout() {
  //  if (!store.getState().user.loggedIn) {
        store.dispatch(push("/login"))
  //  }
}