import * as types from "./types";
const initialState = {
  loggedIn: true
} as types.UserState;

export default function reducer(
  state: types.UserState = initialState,
  action: types.UserAction
): types.UserState {
  switch (action.type) {
    case "LoginSuccess":
      return {
        ...state,
        loggedIn: true
      };
    case "SetUser":
      return {
        ...state,
        user: action.partner
      };
    case "SetPayment":
      return {
        ...state,
        payment: action.payment,
        paymentFailed: false
      };
    case "SetStats":
      return {
        ...state,
        stats: action.statistics,
        statsFailed: false
      };
    case "StatsFailed":
      return {
        ...state,
        statsFailed: true
      };
    case "StatsReset":
      return {
        ...state,
        statsFailed: false
      };
    case "PaymentReset":
      return {
        ...state,
        paymentFailed: false
      };
    case "PaymentFailed":
      return {
        ...state,
        paymentFailed: true
      };
    default:
      return state;
  }
}
