import * as React from "react";
import "../App.css";
import Typography from "material-ui/Typography";
import Button from "material-ui/Button";
import Card, { CardContent } from "material-ui/Card";
import CopyToClipboard from "react-copy-to-clipboard";

export default function AffiliateLinkBox(props: { link: string }) {
  return (
    <div style={{ height: "100%" }}>
      <Card style={{ height: "100%" }}>
        <CardContent>
          <div style={{ display: "flex" }}>
            <h1 style={{ fontSize: 20, flex: 1 }}>Your Affiliate Link</h1>
            <CopyToClipboard onCopy={() => {}} text={props.link}>
              <Button
                style={{
                  color: "#00abcc",
                  marginTop: 8,
                  marginBottom: 8,
                  border: "1px solid #00abcc"
                }}
                dense
                title="Copy"
              >
                Copy
              </Button>
            </CopyToClipboard>
          </div>
          <Typography
            style={{
              fontSize: 20,
              flex: 1,
              border: "#000000",
              fontFamily: "'Varela Round', sans-serif",
              margin: 16,
              padding: 8,
              borderStyle: "solid",
              borderWidth: 1,
              textAlign: "center"
            }}
            type="headline"
            component="h2"
          >
            <b>{props.link}</b>
          </Typography>
          <span
            style={{
              textAlign: "center",
              fontFamily: "'Varela Round', sans-serif"
            }}
          >
            We track the people who comes through this link. You can share on
            any platform or blog.
          </span>
        </CardContent>
      </Card>
    </div>
  );
}
