import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import NoAuth from "./pages/noauth";
import store, { history } from "./store";
import * as routes from "./routes";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import { isAuthenticated, userGroups } from "./auth";

const AuthRoute = ({
  component: Component,
  ...rest
}: {
  component: React.ComponentClass;
  groups: string[];
  path: string;
  exact?: boolean;
}) => {
  const hasAuth = isAuthenticated();
  let hasPermission = false;
  if (hasAuth) {
    const groups = userGroups();
    if (groups) {
      for (let rg of rest.groups) {
        if (groups.indexOf(rg) > -1) {
          hasPermission = true;
          break;
        }
      }
    }
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (!hasAuth) {
          return (
            <Redirect
              to={{
                pathname: routes.PATH_LOGIN,
                state: { from: props.location }
              }}
            />
          );
        }
        if (hasPermission) {
          return <Component {...props} />;
        } else {
          return <NoAuth />;
        }
      }}
    />
  );
};

const AFFILIATE_GROUPS = [
  "otsimo.com/admin",
  "otsimo.com/owner",
  "otsimo.com/partner"
];

function Routes() {
  return (
    <div>
      <AuthRoute
        groups={AFFILIATE_GROUPS}
        path={routes.PATH_MAIN}
        component={Dashboard}
        exact
      />
      <Route path={routes.PATH_LOGIN} component={Login} exact />
    </div>
  );
}

export class Nav extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        <Provider store={store as any}>
          <ConnectedRouter history={history}>
            <div>
              <Routes />
            </div>
          </ConnectedRouter>
        </Provider>
      </div>
    );
  }
}
